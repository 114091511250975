<template>
  <v-card
    flat
    :ripple="false"
    tile
  >
    <v-list-item
      two-line
    >
      <v-list-item-content>
        <v-list-item-title
          class="break-word"
        >
          {{ formattedItem.title }}
        </v-list-item-title>
        <v-list-item-subtitle
          v-snip:js="5"
          class="break-word"
          v-html="formattedItem.subtitle"
        />
      </v-list-item-content>
    </v-list-item>
    <v-card-actions>
      <v-chip
        v-if="formattedItem.ffijada"
        label
        dark
        color="red"
        small
        class="mr-1"
      >
        <span
          v-if="formattedItem.horaFijada === '00:00'"
        >
          Fijado
        </span>
        <span
          v-else
        >
          Fijado {{ formattedItem.horaFijada }} hs
        </span>
      </v-chip>
      <v-chip
        v-if="formattedItem.parte_trabajo.clasificacion !== PARTE_TRABAJO.clasificaciones.normal"
        color="info"
        label
        small
        class="mr-1"
      >
        {{ PARTE_TRABAJO.descripcionesClasificacion[formattedItem.parte_trabajo.clasificacion] }}
      </v-chip>
      <v-chip
        :style="{ 'background-color': formattedItem.prioridad_color_fondo, 'color': formattedItem.prioridad_color_font }"
        label
        small
        class="mr-1"
      >
        {{ formattedItem.prioridad_alias ? formattedItem.prioridad_alias : formattedItem.prioridad_descripcion }}
      </v-chip>
      <v-chip
        label
        :color="formattedItem.colorTactuacion"
        :text-color="formattedItem.colorTextoTactuacion"
        small
        class="mr-1"
      >
        {{ formattedItem.tactuacion_tipo_desc }}
      </v-chip>
      <v-chip
        v-if="formattedItem.cant_acciones_pendientes"
        label
        :color="PARTE_TRABAJO.colores.conAccionesPendientes"
        small
        class="mr-1"
      >
        {{ formattedItem.cant_acciones_realizadas }}/{{ formattedItem.cant_acciones }}
      </v-chip>
      <v-chip
        v-else-if="formattedItem.cant_acciones"
        label
        :color="PARTE_TRABAJO.colores.conAccionesTodasRealizadas"
        text-color="white"
        small
        class="mr-1"
      >
        {{ formattedItem.cant_acciones }}
      </v-chip>
      <v-icon
        v-show="formattedItem.idtmotivo_bloqueo_ot"
        class="mr-1 mt-1"
        small
        color="red"
      >
        {{ $vuetify.icons.values.locked }}
      </v-icon>
      <v-icon
        v-show="formattedItem.avisar_antes_ir"
        class="mr-1 mt-1"
        small
        color="red"
      >
        {{ $vuetify.icons.values.avisarAntesDeIr }}
      </v-icon>
      <v-icon
        v-show="formattedItem.fprevista"
        class="mr-1 mt-1"
        small
      >
        {{ $vuetify.icons.values.calendar }}
      </v-icon>
      <v-icon
        v-show="formattedItem.observacion"
        class="mr-1 mt-1"
        small
      >
        {{ $vuetify.icons.values.chat }}
      </v-icon>
      <v-spacer />
      <v-btn
        v-if="formattedItem.checklist_ot.idchecklist_ot"
        icon
        @click.stop="clickChecklist(formattedItem)"
      >
        <v-icon>{{ $vuetify.icons.values.checklist }}</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { TACTUACION, CHECKLIST, PARTE_TRABAJO } from '@/utils/consts'
import _ from '@/utils/lodash'
import { fontColor } from '@/utils/ui'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0,
    },
    selectMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      PARTE_TRABAJO
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$offline.parteTrabajo.title(item)
      item.subtitle = this.$offline.parteTrabajo.subtitle(item)
      item.fechaPrevista = `${this.$options.filters.shortDate(item.orden_trabajo.fprevista)}`
      item.fechaFijada = `${this.$options.filters.shortDate(item.parte_trabajo.ffijada)}`
      item.horaFijada = `${this.$options.filters.shortTime(item.parte_trabajo.ffijada)}`
      item.ffijada = item.parte_trabajo.ffijada
      item.fprevista = item.orden_trabajo.fprevista
      item.avisar_antes_ir = item.orden_trabajo.avisar_antes_ir
      item.observacion = item.orden_trabajo.observacion
      item.prioridad_descripcion = item.orden_trabajo_prioridad.descripcion
      item.tactuacion_tipo_desc = TACTUACION.descripciones[item.tactuacion.tipo]
      item.prioridad_color_fondo = item.orden_trabajo_prioridad.color_fondo
      item.prioridad_color_font = item.orden_trabajo_prioridad.color_font
      item.prioridad_alias = item.orden_trabajo_prioridad.alias
      if (item.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente) {
        item.pendiente = true
        item.finalizado = false
      } else {
        item.pendiente = false
        item.finalizado = true
      }
      item.colorTactuacion = item.checklist_con_anomalias ? CHECKLIST.colores.conAnomalias : null
      item.colorTextoTactuacion = item.checklist_con_anomalias ? fontColor(CHECKLIST.colores.conAnomalias) : null
      return item
    },
  },
  methods: {
    clickChecklist (item) {
      this.$appRouter.push({
        name: 'offline__checklist-view',
        params: {
          idparte_trabajo: item.parte_trabajo.idparte_trabajo,
          idchecklist_ot: item.checklist_ot.idchecklist_ot
        }
      })
    },
  }
}
</script>

<style scoped>
.break-word {
  word-break: break-word;
  white-space: normal;
}
</style>
